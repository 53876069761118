"use client" // Error components must be Client Components

import { useEffect } from "react"
import { PageHeading } from "src/components/PageHeading"
import { DefaultPageLayout } from "src/layouts/DefaultPageLayout"

export const ErrorPage = ({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <DefaultPageLayout>
      <PageHeading>Fehler</PageHeading>
      <p>Das hätte nicht passieren sollen...</p>
      <button onClick={reset}>Nochmal versuchen</button>
    </DefaultPageLayout>
  )
}

export default ErrorPage
